import { createRouter, createWebHashHistory } from "vue-router";

// 配置路由
const routes = [
  // 配置home路由
  {
    path: "/", // 路径
    name: "Home", // 路由名称
    component: () => import("../views/home/Home.vue"), // 对应的组件
  },
  // 配置shop路由
  {
    path: "/shop/:id",
    name: "Shop",
    component: () => import("../views/shop/Shop.vue"),
  },
  // 配置确认order的路由
  {
    path: "/order-confirmation/:id",
    name: "OrderConfirmation",
    component: () => import("../views/order/OrderConfirmation.vue"),
  },
  // 配置orderList路由
  {
    path: "/order-list",
    name: "OrderList",
    component: () => import("../views/order/OrderList.vue"),
  },
  // 配置地址列表路由
  {
    path: "/address-list",
    name: "AddressList",
    component: () => import("../views/address/AddressList.vue"),
  },
  // 配置地址创建路由
  {
    path: "/address-create",
    name: "AddressCreate",
    component: () => import("../views/address/AddressCreate.vue"),
  },
  // 配置地址编辑路由
  {
    path: "/address-edit/:id",
    name: "AddressEdit",
    component: () => import("../views/address/AddressEdit.vue"),
  },
  // 配置购物车路由
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/cart/Cart.vue"),
  },
  // 配置用户中心路由
  {
    path: "/user",
    name: "User",
    component: () => import("../views/user/User.vue"),
  },
  // 配置register路由
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/register/Register.vue"),
    // 访问register页面之前执行
    beforeEnter: (to, from, next) => {
      // 判断是否登录, 如果登录了, 则跳转到首页, 否则继续执行
      if (localStorage.isLogin === "true") {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  // 配置login路由
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
    // 访问login页面之前执行
    beforeEnter: (to, from, next) => {
      // 判断是否登录, 如果登录了, 则跳转到首页, 否则继续执行
      if (localStorage.isLogin === "true") {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
];

// 创建路由对象
const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes, // 路由配置
});

router.beforeEach((to, from, next) => {
  // 判断是否登录, 如果登录了, 则继续执行, 否则跳转到登录页面
  if (
    localStorage.isLogin === "true" ||
    to.name === "Login" ||
    to.name === "Register"
  ) {
    // 或者没有登录, 但是要跳转到登录页面, 或者注册页面, 则继续执行
    next();
  } else {
    // 跳转到登录页面
    next({ name: "Login" });
  }
});

// 导出路由对象
export default router;
