<template>
	<!-- 对应的组件的渲染的页面 -->
	<router-view></router-view>
</template>

<script>
export default {
	name: "App",
};
</script>
